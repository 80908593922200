import { copyText } from "./copyText.js";
export default {
  data() {
    return {};
  },
  methods: {
    // 定时器
    sleep: (time) => new Promise((resolve, reject) => setTimeout(resolve, time, "done")),
    copyFn(text) {
      copyText(text, (text) => {
        this.$toast.success(this.$t("TabPane.copy"));
      });
    },
    /** 保留两位小数 */
    unit(num) {
      if (num > 1000) return (num / 1000).toFixed(2) + "k";
      if (num > 10000) return (num / 10000).toFixed(2) + "w";
      if (num == 0) return "0";
      return num + '';
    },
    /** 不保留两位小数 */
    unit2(num) {
      if (num > 1000) return ~~(num / 1000) + "k";
      if (num > 10000) return ~~(num / 10000) + "w";
      if (num == 0) return "0";
      return num + "";
    },
  },
};
