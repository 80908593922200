<template>
  <el-dialog :visible="show" title="提现" :width="registerDialogWidth" @close="$emit('update:show', false)" center :modal-append-to-body="false">
    <!-- <van-dialog :value="show" title="提现" show-cancel-button @confirm="txFn()" @cancel="$emit('update:show', false)"> -->
    <div>
      <div class="text-8D94BF px-3 text-center text-9">
        {{ $t("payModal.payModal11") }}
        <div>{{ $t("payModal.payModal12") }}</div>
        <!-- <div>请输入您要提现的积分数量</div>
        <div>1.1积分=1USDT</div>
        <div>2.20积分起</div>
        <div>3.必须是5的倍数</div> -->
      </div>
      <!-- type="digit" -->
      <van-field class="Frame59" v-model.number="price" :placeholder="$t('payModal.payModal13')" />
    </div>
    <!-- </van-dialog> -->
    <div class="text-center pt-5">
      <button class="btn text-white rounded-pill w-75" :class="loading ? 'bg-d8d8d8' : 'bg-dark'" :disabled="loading" @click="txFn()">{{ $t("payModal.payModal3") }}</button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: { show: Boolean },
  data() {
    return {
      price: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions("user", ["getPropertys"]),
    ...mapActions("webAbi", ["mintToChain", "getAuth", "getAddress"]),
    /**
     * 提现接口
     * @param {积分} u
     */
    async txFn() {
      this.$emit("update:show", false);
      if (!this.price) return this.$toast(this.$t("payModal.payModal20"));
      if (this.price > this.propertys[303]) return this.$toast(this.$t("payModal.payModal21"));
      // if (process.env.NODE_ENV !== "development") {
      if (this.price < 10) return this.$toast(this.$t("payModal.payModal22"));
      if (this.price % 5 != 0) return this.$toast(this.$t("payModal.payModal15"));
      // }
      if (this.loading) return;
      this.loading = true;
      try {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t("payModal.payModal17"),
        });
        // // 检查是否
        // await this.getAuth(this.mintAddress);
        var u = this.price + "";
        const { data: res } = await this.$http.post("/app/mint  ", { array: ["303", u + ""] });
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t("payModal.payModal18"),
        });
        await this.mintToChain({ address: this.address, data: res.array, key: res.key });
        this.$toast.success(this.$t("payModal.payModal19"));
        // this.getList();
      } catch (error) {}
      await this.getPropertys();
      this.$toast.clear();
      this.loading = false;
    },
  },
  async mounted() {},
  computed: {
    ...mapState("user", ["address", "propertys"]),
    ...mapState("webAbi", ["web3", "mintAddress"]),
    ...mapState("app", ["registerDialogWidth"]),
  },
  watch: {
    async show() {
      if (this.show) {
        this.getPropertys(); // 刷新用户属性
      }
    },
  },
};
</script>

<style lang="scss"></style>
