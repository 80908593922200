<template>
  <el-dialog :modal-append-to-body="false" :visible="show" :title="$t('payModal.payModal6')" :width="registerDialogWidth" @close="$emit('update:show', false)" center>
    <div class="border-top border-bottom">
      <div class="text-9 text-center pt-3 text-8D94BF">{{ $t("payModal.payModal6") }}</div>
      <van-radio-group v-model="vip" class="pt-3 pb-2 text-9 text-dark">
        <div class="d-flex align-items-center py-1" v-for="item in vipList" :key="item.vip">
          <img class="img2-1" :src="require(`@/assets/img/vip/${item.vip}.png`)" alt="" />
          <div class="pl-1">{{ item.text }}</div>
          <div class="flex-fill">
            <span class="vip-tag text-7">{{ $t("payModal.payModal7") }}{{ item.xs }}</span>
          </div>
          <div class="pr-3">{{ item.price }} USDT</div>
          <van-radio :disabled="item.vip <= propertys[100] || propertys[102] == 2" :name="item.vip" label-position="left" checked-color="#000"></van-radio>
        </div>
      </van-radio-group>
    </div>
    <div class="d-flex justify-content-between text-9 font-weight-bold text-dark py-2">
      <span>{{ $t("payModal.payModal8") }}</span>
      <span>{{ price }} USDT</span>
    </div>
    <div class="d-flex justify-content-between text-7 text-8D94BF">
      <span>{{ $t("wallet.balance") }}:</span>
      <span>{{ usdt }} USDT</span>
    </div>
    <div class="d-flex justify-content-end align-items-center" :class="isPay ? '' : 'visibility-hidden'">
      <img class="img1" src="@/assets/iconfont/vip/ired.svg" alt="" />
      <span class="text-7 text-F1333B pl-1">{{ $t("payModal.payModal9") }}</span>
    </div>
    <div class="text-center pt-5">
      <button class="btn text-white rounded-pill w-75" :class="loading || isPay ? 'bg-d8d8d8' : 'bg-dark'" :disabled="loading || isPay" @click="payV(price, 2)">{{ $t("payModal.payModal3") }}</button>
    </div>
  </el-dialog>
</template>

<script>
import pay from "@/mixin/pay";
import { mapState } from "vuex";
export default {
  mixins: [pay],
  data() {
    return {
      vip: 0, // 选择vip等级
      vipList: [
        { vip: 1, text: this.$t("vip.vip8"), xs: "1.0", price: 10 },
        { vip: 2, text: this.$t("vip.vip11"), xs: "1.2", price: 100 },
        { vip: 3, text: this.$t("vip.vip12"), xs: "1.3", price: 300 },
        { vip: 4, text: this.$t("vip.vip13"), xs: "1.5", price: 500 },
      ],
      loading: false,
      usdt: 0,
    };
  },
  components: {},
  methods: {
    /**
     * 充值接口
     * @param {充值的金额} u
     */ 
    async payV(price, type = 2) {
      if (this.propertys[102] >= 2) return this.$toast(this.$t("wallet.payVip"));
      this.cz(price, (type = 2));
    },
  },
  async mounted() {},
  computed: {
    ...mapState("app", ["registerDialogWidth"]),
    isPay() {
      if (!this.vip) return false;
      return this.vipList[this.vip - 1].price > this.usdt;
    },
    price() {
      return this.vip ? this.vipList[this.vip - 1].price : 0;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.textcontent {
  color: #000000de;
  font-family: Microsoft YaHei;
  font-size: 1.5rem;
  line-height: 1.656rem;
  text-align: center;
}
</style>
