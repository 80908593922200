import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { Dialog, Toast } from "vant";
import i18n from "@/lang";
import baseUrlObj from "./bsseUrl";
import zh from "@/lang/zh";
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'https://resapi.tdd028.cn',
  // baseURL: 'http://164.88.188.176:80', // 线上
  baseURL: baseUrlObj.url1,
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (store.state.user.authorization) {
      config.headers["Authorization"] = store.state.user.authorization;
      // config.headers["Token"] = store.state.user.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    ///////////////////////////////错误码////////////////////////////////
    if (res.errorCode !== 0) {
      const errorCode = new Map(Object.keys(zh.request).map((key) => [key, i18n.t(`request['${key}']`)]));
      if (errorCode.get(res.errorCode + '')) {
        Toast({
          message: errorCode.get(res.errorCode + '') || i18n.t("request.request40"),
          type: "fail",
          duration: 5 * 1000,
        });
      }
      if (res.errorCode == -207) {
        Dialog.alert({
          title: i18n.t("DialogText.tips"),
          message: i18n.t("request.request42"),
        });
        store.commit("user/outLogin");
      }

      return Promise.reject(response);
    } else {
      return response;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Toast({
      message: error.message,
      type: "Toast",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
