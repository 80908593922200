<template>
  <div id="app" class="iconfont">
    <el-row style="margin: 0;height: 100vh;background-color: #000;overflow-y: auto;" :gutter="10" type="flex" justify="center">
      <!-- 自适应布局 -->
      <el-col style="position: relative" class="px-0" :xs="24" :sm="18" :md="12" :lg="8" :xl="8">
        <router-view />
      </el-col>
    </el-row>
    <BottomNavbar v-if="this.token" />
    <div class="z_dialog">
      <!-- 购买vip -->
      <payVipModal :show.sync="show"></payVipModal>
      <!-- 充值 -->
      <payUSDTModal :show.sync="payShow"></payUSDTModal>
      <!-- 申请提现 -->
      <toUSDTModal :show.sync="toUShow"></toUSDTModal>
      <!-- 提现到钱包 -->
      <toWallet :show.sync="toWalletShow"></toWallet>
    </div>
  </div>
</template>

<script>
import payUSDTModal from "@/components/payModal/payUSDTModal.vue";
import payVipModal from "@/components/payModal/payVipModal.vue";
import toUSDTModal from "@/components/payModal/toUSDTModal.vue";
import toWallet from "@/components/payModal/toWallet.vue";
import { mapState, mapMutations } from "vuex";
import BottomNavbar from "./components/BottomNavbar/BottomNavbar.vue";
export default {
  name: "App",
  components: {
    BottomNavbar,
    payVipModal,
    payUSDTModal,
    toUSDTModal,
    toWallet,
  },
  data() {
    return { show: false, payShow: false, toUShow: false, toWalletShow: false };
  },
  async mounted() {
    console.log('url', window.location.href);
    this.getIvCode();
    await this.sleep(1000);
    // this.getAddress();
    this.$bus.$on("payVip", () => (this.token ? (this.show = true) : this.diaLogin()));
    this.$bus.$on("payUSDT", () => (this.token ? (this.payShow = true) : this.diaLogin()));
    this.$bus.$on("toUSDT", () => (this.token ? (this.toUShow = true) : this.diaLogin()));
    this.$bus.$on("toWallet", () => (this.token ? (this.toWalletShow = true) : this.diaLogin()));
  },
  methods: {
    ...mapMutations("user", ["SET_IVCODE"]),
    diaLogin() {
      this.$dialog({
        title: this.$t('DialogText.tips'),
        message: this.$t('vuex.vuex3'),
      });
    },
    getIvCode() {
      if (window.location.href.split("?")[1]) {
        var queryData = window.location.href.split("?")[1]
        var ivCodeUrl = queryData.split("&")[0]
        var ivCode = ivCodeUrl.split("=")
        if (ivCode[0] == 'share') {
          this.SET_IVCODE(ivCode[1].toLowerCase());
        }
      }
    },
  },
  computed: {
    ...mapState("user", ["token"]),
  },
};
</script>

<style lang="scss">
@import "@/assets/css/bootstrap.css";
@import "@/assets/css/base.scss";
@import "@/assets/css/iconfont.css";
@import "@/assets/css/common.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
.content {
  padding-top: 4.084rem;
}
.z_dialog {
  .v-modal {
    z-index: 3000 !important;
  }
  .el-dialog__wrapper {
    /* margin: auto; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3001 !important;
  }
  .el-dialog {
    margin: 0 !important;
    border-radius: 1.6rem !important;
  }
  .vip-tag {
    margin-left: 0.5rem;
    color: #edb81f;
    border-radius: 0rem 0.3rem 0.3rem 0.3rem;
    border: 0.1rem solid #edb81f;
    padding: 0.1rem 0.2rem;
  }
  .Frame59 {
    margin-top: 1rem;
    border-radius: 0.4rem;
    border: 1px solid #1f1f1f;
  }
}
// .van-toast.van-toast--middle.van-toast--loading {
.van-toast.van-toast--middle {
  z-index: 4000 !important;
}
.van-dialog {
  z-index: 4000 !important;
}
.vote_bottom_right_text{
  img{
    width: 1rem;
    height: 1rem;
  }
}
</style>
