
// import moment from 'moment'
// export function setTime(e, type = 'YYYY-MM-DD HH:mm') {
//   return moment(e).format(type)
// }
// export function setTimess(e, type = 'YYYY-MM-DD HH:mm:ss') {
//   return moment(e).format(type)
// }
// // 获取时间戳
// export function getTimeStamp(e) {
//   return moment(e).utc().valueOf()
// }
// // 获取当前时间戳
// export function getCurrentStamp() {
//   return moment().utc().valueOf()
// }
// 合约地址  隐藏中间部分
export function contractStr(str = '', def = '-') {
	if (!str || str.length < 10) return def
	return str.replace(/^(\w{4})\w+(\w{4})$/, "$1...$2")
}
export function contractStr1(str = '', def = '-') {
	if (!str || str.length < 10) return def
	return str.replace(/^(\w{2})\w+(\w{2})$/, "$1...$2")
}

export function addressFl(str = '', def = '-') {
	if (!str || str.length < 10) return def
	return str.replace(/^(\w{8})\w+(\w{6})$/, "$1...$2")
}