import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";

Vue.use(Vuex);

const modulesFiles = require.context("./modules", true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

import vuejsStorage from "vuejs-storage";
export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [
    vuejsStorage({
      keys: ["user.address", "user.refAddress", "user.token", "user.authorization", "user.propertys", "user.ivCode"],
      //keep state.count in localStorage
      namespace: "user",
      driver: vuejsStorage.drivers.localStorage,
      //if you want to use sessionStorage instead of localStorage
    }),
  ],
});
