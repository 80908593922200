const baseUrlObj = {
  url1: "https://www.ploutoonline.com/api1", // APP相关
  url5: "https://www.ploutoonline.com/api2", // 竞拍
  // url1: "http://5.102.120.96:8985", // APP相关
  // url5: "http://5.102.120.96:9386", // 竞拍
};
if (process.env.NODE_ENV === "development") {
  Object.assign(baseUrlObj, {
    url1: "http://8.218.189.134:8985", // APP相关
    url5: "http://8.218.189.134:9386", // 竞拍
    // url1: "http://66.103.214.240:8985", // APP相关
    // url5: "http://66.103.214.240:9386", // 竞拍
    // url1: "https://www.ploutoonline.com/api1/", // APP相关
    // url5: "https://www.ploutoonline.com/api2/", // 竞拍
  });
}

export default baseUrlObj;
