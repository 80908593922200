import Web3 from "web3";
import IERC20 from "../abi/IERC20.json";
import GuessMint from "../abi/GuessMint.json";
import GuessRecharge from "../abi/GuessRecharge.json";
import silvABI from "../abi/silvABI.json";
import GOLDToken from "../abi/GOLDToken.json";
import i18n from "@/lang";
import { Dialog, Toast } from "vant";

const netWorkUrl = {
  // 1: {
  //   name: "Ethereum  以太坊",
  //   erc20Address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  //   mintAddress: "", // 铸造
  //   rechargeAddress: "", // 充值
  // },
  137:
    process.env.NODE_ENV === "development"
      ? {
          name: "Polygon 马蹄",
          erc20Address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
          mintAddress: "0x80a476300f7a83e9571c7bf6ec0108cab18e6a1b",
          rechargeAddress: "0x77e690ee87b18010Ac48CcEC70471b5850e65964",
          silvAddress: "0x2974fbff749f4e2a2b8f1d75e6b778a5f040a11b",
          goldAddress: '0x8a76352b43c9c0339afc3b9f620ee63ef80faba3',
          unit: "mwei",
        }
      : {
          name: "Polygon 马蹄",
          erc20Address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
          mintAddress: "0x80a476300f7a83e9571c7bf6ec0108cab18e6a1b",
          rechargeAddress: "0x77e690ee87b18010Ac48CcEC70471b5850e65964",
          silvAddress: "0x2974fbff749f4e2a2b8f1d75e6b778a5f040a11b",
          goldAddress: '0x8a76352b43c9c0339afc3b9f620ee63ef80faba3',
          unit: "mwei",
        },
  // 1936529372: {
  //   name: "TRON 波场",
  //   erc20Address: "",
  //   mintAddress: "",
  //   rechargeAddress: "",
  // },
  // 1936529372: 'Binance 币安',
  56:
    process.env.NODE_ENV === "development"
      ? {
          name: "bnb 币安",
          erc20Address: "0x55d398326f99059ff775485246999027b3197955",
          mintAddress: "0x28964aC4A99fa8fc810c7E401274bBA8D2b36D02",
          rechargeAddress: "0x77e690ee87b18010Ac48CcEC70471b5850e65964",
          silvAddress: "0xcF3Dabaf0B14f08BCa6f77C45369fd4701797EA0",
          goldAddress: '0x777eA9aed7B45d6ACd1F95EE6f543DD50C0a5CDa',
        }
      : {
          name: "bnb 币安",
          erc20Address: "0x55d398326f99059ff775485246999027b3197955",
          mintAddress: "0x3521Cf9aB392Cf007A340FD64025Cd317792045B",
          rechargeAddress: "0x77e690ee87b18010Ac48CcEC70471b5850e65964",
          silvAddress: "0x053B2CD0154a93242740230b30817E75AeD6bF86",
          goldAddress: '0x1D2C679A2D6eECB5e6D62eb2343a33ABD104c370',
          unit: "ether",
        },
};

// const web3 = new Web3(Web3.givenProvider || "https://polygon-mainnet.infura.io/v3/b0a18aaf46084e0b93ce79ae282c3ff2");
const state = {
  address: "",
  // 合约地址
  erc20Address: "",
  mintAddress: "", // 铸造
  rechargeAddress: "", // 充值
  silvAddress: "", // 竞拍
  goldAddress: '', //金币
  web3: null,
  erc20: null, // erc20   usdt交易abi
  mint: null, // 铸造abi
  recharge: null, // 充值abi
  silvWeb3: null, // 竞拍
  goldWeb3: null, // 金币
  getGoldWeb3: null, // 查询金币
  isInit: false, // 是否初始化了
  netWorkId: 0, // 网路id
  unit: "",
};

const mutations = {};

const actions = {
  /**
   * 初始化
   * @returns
   */
  async abiInit({ state, dispatch }) {
    if (state.isInit) return state.isInit;
    try {
      if (window.ethereum != "undefined") {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: i18n.t("vuex.vuex1"),
        });
        // state.web3 = new Web3(Web3.givenProvider);
        state.web3 = new Web3(ethereum);
        // 137 polygon 马蹄
        const id = await state.web3.eth.net.getId();
        console.log("网路id", id, netWorkUrl[id].name);
        if (!netWorkUrl[id]) {
          Dialog({ title: i18n.t("DialogText.tips"), message: i18n.t("login.network"), confirmButtonText: i18n.t("DialogText.cancel") });
          Toast.clear();
          return;
        }
        Object.assign(state, netWorkUrl[id]);
        state.netWorkId = id;
        const [address] = await state.web3.eth.requestAccounts();
        state.address = address;
        const gasPrice = parseInt(await state.web3.eth.getGasPrice());
        // let gasPrice = 52079715908
        state.erc20 = new state.web3.eth.Contract(IERC20, state.erc20Address).methods;
        state.mint = new state.web3.eth.Contract(GuessMint, state.mintAddress, { from: address, gasPrice }).methods;
        state.recharge = new state.web3.eth.Contract(GuessRecharge, state.rechargeAddress, { from: address, gasPrice }).methods;
        state.goldWeb3 = new state.web3.eth.Contract(GOLDToken, state.goldAddress, { from: address, gasPrice }).methods;
        state.getGoldWeb3 = new state.web3.eth.Contract(IERC20, state.goldAddress).methods;
        state.isInit = true;
        Toast.clear();
      } else {
        Toast.clear();
        Dialog({ title: i18n.t("DialogText.tips"), message: i18n.t("login.installMetaMask"), confirmButtonText: i18n.t("DialogText.cancel") });
        return Promise.reject(i18n.t("vuex.vuex2"));
      }
    } catch (error) {
      Toast.clear();
    }
  },
  /**
   * 获取用户当前 钱包地址
   * @returns
   */
  async getAddress({ state, dispatch }) {
    await dispatch("abiInit");
    const [myAddress] = await state.web3.eth.getAccounts(); // 钱包地址
    // const [myAddress] = await state.web3.eth.requestAccounts(); // 钱包地址
    return myAddress;
  },
  /**
   * 检查是否登录
   * @param {*} param0
   * @returns
   */
  async checkToken({ rootState }) {
    if (rootState.user.token) return;
    Dialog.alert({
      title: i18n.t("DialogText.tips"),
      message: i18n.t("vuex.vuex3"),
    });
    return Promise.reject(i18n.t("vuex.vuex4"));
  },
  /**
   * 获取马蹄数量
   * @param {*} param0
   * @returns string
   */
  async getMatic({ state, dispatch }) {
    var address = await dispatch("getAddress");
    return state.web3.utils.fromWei(await state.web3.eth.getBalance(address), process.env.NODE_ENV === "development" ? "wei" : state.unit);
  },
  /**
   * 获取用户钱包usdt余额
   * @param {单位(wei=>1; mwei=>10**6)} type
   * @param {钱包地址} address
   */
  async getUSDT({ state, dispatch }, { type = "mwei", address }) {
    await dispatch("checkToken");
    await dispatch("abiInit");
    // 获取用户钱包usdt余额
    let usdt = await state.erc20.balanceOf(address).call();
    return state.web3.utils.fromWei(usdt, process.env.NODE_ENV === "development" ? type : state.unit);
  },
  /**
   * 获取用户钱包金币余额
   * @param {单位(wei=>1; mwei=>10**6)} type
   * @param {钱包地址} address
   */
  async getGOLD({ state, dispatch }, { type = "mwei", address }) {
    await dispatch("checkToken");
    await dispatch("abiInit");
    // 获取用户钱包金币余额
    let gold = await state.getGoldWeb3.balanceOf(address).call();
    return state.web3.utils.fromWei(gold, process.env.NODE_ENV === "development" ? type : state.unit);
  },
  /**
   * 检测用户是否授权 合约
   * @param {用户钱包地址} address
   */
  async getAuth({ state, dispatch }, address) {
    await dispatch("checkToken");
    await dispatch("abiInit");
    // 检查是否
    let allowance = await state.erc20.allowance(state.address, address).call();
    // 未授权  去授权
    if (allowance <= 0) {
      Toast.clear();
      await Dialog.confirm({
        title: i18n.t("DialogText.tips"),
        message: i18n.t("wallet.approve"),
        cancelButtonText: i18n.t("DialogText.cancel"),
        confirmButtonText: i18n.t("DialogText.approve"),
        overlayStyle: {
          "z-index": 4000,
        },
      });
      await state.erc20.approve(address, state.web3.utils.toWei("999999999999999999999999999999", state.unit) + "").send({ from: state.address });
      throw "授权中";
    }
  },
  /**
   * 检测用户是否授权 合约
   * @param {用户钱包地址} address
   */
  async getAuth2({ state, dispatch }, address) {
    await dispatch("checkToken");
    await dispatch("abiInit");
    // 检查是否
    let allowance = await state.erc20.allowance(state.address, address).call();
    // 未授权  去授权
    if (allowance <= 0) {
      await Dialog.confirm({
        title: i18n.t("DialogText.tips"),
        message: i18n.t("wallet.approve"),
        cancelButtonText: i18n.t("DialogText.cancel"),
        confirmButtonText: i18n.t("DialogText.approve"),
        overlayStyle: {
          "z-index": 4000,
        },
      });
      await state.erc20.approve(address, state.web3.utils.toWei("99999999", state.unit) + "").send({ from: state.address });
    } else {
      Toast.success("已授权");
    }
  },
  /**
   * 充值或者购买会员
   * @param {用户钱包地址} address
   * @param {1.充值余额 2.购买会员} type
   * @param {金额} amount
   */
  async payVip({ state, dispatch }, { address, type, amount }) {
    await dispatch("checkToken");
    await dispatch("abiInit");
    const gasPrice = parseInt(await state.web3.eth.getGasPrice());
    // 获取充值功能的 gas
    const gas = await state.recharge.recharge(type, amount).estimateGas({ from: address });
    await state.recharge.recharge(type, amount).send({ from: address, gasPrice, gas });
  },
  /**
   * 提现
   * @param {*} param0
   * @param {*} param1
   */
  async mintToChain({ state, dispatch }, { data, key, address }) {
    await dispatch("checkToken");
    await dispatch("abiInit");
    await state.mint
      .mintToChain(
        data,
        // 字符串转字节数组
        key.match(/.{1,2}/g).map((v) => parseInt(v, 16))
      )
      .send({ from: address });
  },
  /**
   * 初始化竞拍合约
   */
  async initSilvAbi({ state, dispatch }) {
    await dispatch("abiInit");
    const ethContract = new state.web3.eth.Contract(silvABI, state.silvAddress);
    state.silvWeb3 = ethContract.methods;
  },
  /**
   * 支付竞拍订单
   */
  async payAuction({ state, dispatch }, { array, key, address }) {
    await dispatch("initSilvAbi");
    const keys = key.match(/.{1,2}/g).map((v) => parseInt(v, 16));
    const gasPrice = parseInt(await state.web3.eth.getGasPrice());
    const gas = await state.silvWeb3.payAuction(array, keys).estimateGas({ from: address });
    await state.silvWeb3.payAuction(array, keys).send({ from: address, gasPrice, gas });
  },

  /**
   * 寄售竞拍订单
   */
  async createAuction({ state, dispatch }, { array, key, address }) {
    await dispatch("initSilvAbi");
    const keys = key.match(/.{1,2}/g).map((v) => parseInt(v, 16));
    const gasPrice = parseInt(await state.web3.eth.getGasPrice());
    const gas = await state.silvWeb3.createAuction(array, keys).estimateGas({ from: address });
    await state.silvWeb3.createAuction(array, keys).send({ from: address, gasPrice, gas });
  },
  /**
   * 金币换U
   */
  async goldToU({ state, dispatch }, { num, address }) {
    await dispatch("abiInit");
    await state.goldWeb3.exchange(num).send({ from: address });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
