export default {
  route: {},
  TabPane: {
    details: "Details",
    wallet: "Wallet",
    copy: "Replicating Successful",
  },
  vote: {
    timeRemaining: "Time remaining",
    timeRemaining2: "Delay in progress",
    timeRemaining3: "In Settlement",
    over: "Finished",
    trend: "Trend",
    debate: "Debate",
    votePeople: "Nunber of Debates",
    jackpot: "Jackpot",
    IntegralConsumption: "Points consumption:",
    hot: "Hot",
    new: "New",
    noReason: "NO Reason",
    allReason: "All Reasons",
    noMore: "NO More",
    vote1: "Vote",
    vote2: "Please enter the number of votes",
    vote3: "Cannot be revoked after voting",
    vote4: "Vote successful",
    vote5: "Please enter reason(s)",
    vote6: "like",
  },
  wallet: {
    link: "Connect the wallet to Plouto",
    disconnect: "Disconnect",
    token: "Token",
    credits: "Credits",
    pointDetails: "Points Details",
    incomeDetails: "Income Details",
    exchangeRecord: "Records",
    property: "Property",
    price: "Price",
    balance: "Balance",
    value: "Value",
    exchange: "Withdrawal",
    exchange2: "Apply for withdrawal",
    buy: "Buy",
    exchange3: "Exchange",
    vip: "VIP",
    noVip: "Non VIP",
    creditsTips: "Participating in the quiz costs points, and rewards are awarded through points. Points and USDT can be exchanged in both directions at any time",
    vipTips: "Membership is required to participate in the game.",
    confront: "Confront",
    topUp: "Top Up",
    debate: "Debate",
    hash: "Hash",
    result0: "Pending",
    result1: "Approved",
    result2: "Rejected",
    remaining: "Remaining ",
    USDT: "USDT",
    MATIC: "MATIC",
    rechargeText: "Insufficient USDT balance, please recharge", // 余额不足
    approve: "Your authorization has been detected", // Insufficient balance
    payVip: "VIP can only be purchased 2 times", // Can only buy VIP twice
  },
  Select: {
    all: "All",
    game: "Game",
    enableLists: "Enable List",
    disableLists: "Disable List",
    stop: "STOP",
    run: "Activate",
    language: "Switch Language",
    copylink: "Copy Link",
    reportText: "Report",
  },
  terms: {
    userTerms: "User Terms, Privacy Policy",
    userCovenants: "User Covenants",
    newsletterText: "Receive newsletters from Gogame",
    agreeText: "I have read and agree",
  },
  login: {
    login: "Login",
    loginTips: "Don't have an account yet?",
    register: "Register",
    registerTips: "Already have an account?",
    signIn: "Wallet sign in",
    signUp: "Wallet sign up",
    signOut: "Wallet sign out",
    skip: "Skip",
    SelectInteresting: "Select 3 interesting content",
    SelectInterestingTips: "You can modify it at any time later",
    attentionUser: "Follow thoughtful users",
    attentionUserTips: "Get more creative ideas",
    walletConnection: "Connected wallet",
    selectWallet: "Select Wallet",
    loginPlaceholder: "Referrer's code (required)",
    requiredAddress: "Please fill in the referrer's code",
    selectNetwork: "Select Network",
    selectNetworkTips: "Select the network you use on Gogame. Once selected, It cannot be modified",
    installMetaMask: "You have not installed MetaMask",
    network: "Please switch to BNB Polygon for Wallet Network",
  },
  // Common Text
  DialogText: {
    tips: "Tips",
    confirm: "Confirm",
    cancel: "Cancel",
    export: "Export",
    disable: "Disable",
    enable: "Enable",
    disableTip: "Confirm to disable?",
    enableTip: "Confirm to enable?",
    disableTitle: "Disable",
    enableTitle: "Enable",
    saveTip: "Save it?",
    saveTitle: "Save",
    Imgexpired: "Image expired",
    accept: "Accept",
    and: "and",
    total: "Total",
    next: "Next",
    loading: "Loading",
    approve: "Approve",
    times: "times",
    cpoies: "份",
    developToast: "In development, please stay tuned",
  },
  Popconfirm: {
    confirm: "Confirm",
    cancel: "Cancel",
    refresh: "Refresh",
    title: "Are you sure to delete it?",
    saveChangeTip: "Leaving this page unsaved will delete all changes you made.",
    saveChangeTitle: "Save changes",
    delAttrValTitle: "Delete attribute value(s)",
    delAttrValTip: "You won't be able to recover it.",
  },
  messageInfo: {
    switchLang: "Language switched",
    lockIsOn: "Uppercase is on",
    successfully: "Operation completed",
    deleteSuccess: "Deleted",
    noData: "N/A",
    return: "Back",
    noPermissionLogin: "You don't have the access to log in. Please contact the administrator!",
    sessionOut: "You've been gone for a while; please re-login!",
    serviceBusy: "Server busy",
  },
  Confirm: {
    TIPS: "Delete",
    more: "More",
  },
  home: {
    title: "SHOW THE WORLD WITH DIFFERENT OPTIONS",
    main: "Plouto IS A MULTI-CHAIN BASED WEB3 SOCIAL MEDIA PLATFORM THAT INTEGRATES SOCIAL-FI AND GAME-FI.",
    VIDEOONE: "Showcase One",
    VIDEOTWO: "Showcase Two",
    VIDEOTHREE: "Showcase Three",
    MoreFun: "More fun",
    allHere: "All here",
    ContactUs: "Contact Us",
  },
  game: {
    hashTips: "Good Luck!!",
    confrontTips: "Wisdom's Contest!",
    debateTips: "Collision of Opinions!",
    moreTips: "Stay tuned...",
    gifts: "会员福利",
    giftsTips: "会员专享，超值福利！",
    auction: "竞拍",
    auctionTips: "同创新精彩",
    game1: "Current Jackpot",
    game2: "Invite friends",
    game3: "My Hash Value",
    game4: "Game Rules",
    game5: "In Progress",
    game6: "Finished",
    confrontRule:
      "游戏分红、蓝双方，会员在参与对抗时，必须选择一方进行投注，最低投注额为2积分，可获得随机的开奖码，每次投注金额都会划分3个奖池中。45%进总奖池，20%进红奖池，20%进蓝奖池，5%平台服务费，5%发起人奖励，3%直推奖励，2%间推奖励。<br/>累计投注10000积分，红、蓝奖池加权平分给红蓝双方，连续分红10次或5天时间到期就会进行开奖。<br/>随机抽取一等奖1名  独中总奖池25%金额，<br/>抽取二等奖10名 加权平分总奖池20%金额，<br/>抽取三等奖40名 加权平分总奖池20%金额，<br/>剩余35%奖金加权平分给一等奖中奖者支持的红方或蓝方。<br/>项目发起方；钻石会员+200积分+团队人数100人，才有资格向平台发起。项目每天一个，最多7个项目同时在线，如果有超出的项目可排队等候。<br/>项目时间；为期5天。每个项目都有时间限制，5天为之最长期限。如果项目在5天内完成10次分红，可以提前开奖。",
    pay: "购买成功",
  },
  ranking: {
    hash: "Hash Ranking",
    debate: "Opinion Ranking",
    confront: "Confrontation Ranking",
    question: "Question Queue Ranking",
    money: "Jackpot Ranking",
  },
  user: {
    user1: "Followers",
    user2: "Following",
    user3: "My Earnings",
    user4: "View Earnings Details",
    user5: "My Invitations",
    user6: "View My Team",
    user7: "My Assets",
    user8: "Membership Level",
    user9: "Buy",
    user10: "No data available",
    user11: "Participated in",
    user12: "Initiated by me",
    user13: "Dividends",
    user14: "VIP Purchase Direct Push Award",
    user15: "VIP Purchase Interval Push Award",
    user16: "Game Bet Interval Push Award",
    user17: "Game Bet Direct Push Award",
    user18: "Red-Blue (Payout Earnings)",
    user19: "Red-Blue (Project's Earnings)",
    user20: "Vote (Payout Earnings)",
    user21: "Vote (Project's Earnings)",
    user22: "BT Guess (Project's Earnings)",
    user23: "Airdrop USDT",
    user24: "Change Avatar",
    user25: "Nickname",
    user26: "Enter Nickname",
    user27: "Nickname should be 4-30 characters, no spaces allowed",
    user28: "Audit Failed",
    user29: "Pending Review",
    user30: "Waiting for Release",
    user31: "Settling",
    user32: "Total Votes",
    user33: "Number of Voters",
    user34: "Supported",
    user35: "Withdrawing USDTT",
    user36: "Team Earnings",
    user37: "Referral Earnings ",
    user38: "After the countdown ends, you will not be able to enjoy the commission compensation, and participating in any games can increase the countdown.",
    user39: "对抗(退还创建金额)",
    user40: "投票(退还创建金额)",
    user41: "对抗创建项目",
    user42: "对抗投注",
    user43: "辩论创建项目",
    user44: "辩论投注",
    user45: "BT投注",
    user46: "对抗(红奖池收益)",
    user47: "对抗(蓝奖池收益)",
    user48: "对抗(一等奖)",
    user49: "对抗(二等奖)",
    user50: "对抗(三等奖)",
    user51: "直推奖(竞拍)",
    user52: "间推奖(竞拍)",
    user53: "竞拍增值收益",
    win: "Winning",
    count: "Voted",
  },
  vip: {
    vip1: "Current VIP Level",
    vip2: "Your Current",
    vip3: "VIP Coefficient",
    vip4: "Debate(s) I initiated",
    vip5: "Upcoming",
    vip6: "Initiate Confrontation",
    vip7: "Initiate Debate",
    vip8: "Regular",
    vip9: "Access to all game qualifications",
    vip10: "Upgrade Now",
    vip11: "Silver",
    vip12: "Gold",
    vip13: "Diamond",
    vip14: "Access to game initiation qualifications",
    vip15: "Already at the highest coefficient",
    vip19: "Delay in progress",
    vip16: "In progress",
    vip17: "Finished",
    vip18: "You are not a diamond member yet. Please activate diamond member first",
    vip19:
      "The weight coefficient is a symbol of participating in the game and also a proportion coefficient of dividends. The higher the level, the more dividends will be distributed. Only Diamond members can participate in project initiation.",
    vip20: "NO VIP",
    vip21: "Team level",
    vip22: "您还不是会员，请先开通会员",
    vip23: "我发起的对抗",
  },
  sendVote: {
    sendVote1:
      "Please write down your question reminder: Prohibited from initiating sensitive topics related to politics, country, drugs, firearms, etcPlease write down your question reminder: It is prohibited to initiate sensitive topics related to politics, country, drugs, firearms, etc.",
    sendVote2: "Add Option",
    sendVote3: "Channel",
    sendVote4: "Enter Points",
    sendVote5: "Validity Period",
    sendVote6: "Days",
    sendVote7: "Select Validity Period",
    sendVote8: "Add Channel",
    sendVote9: "Enter Content",
    sendVote10: "Select Channel",
    sendVote11: "Select Validity Period",
    sendVote12: "Upload Image",
    sendVote13: "Successfully Published; Waiting for Review",
    sendVote14: "Uploading... ",
    sendVote15: "Upload Failed",
    sendVote16: "Customize Point Consumption",
    sendVote17: "You can customize the points consumed by participants in voting (minimum 1 point)",
    sendVote18: "Customize Voting Validity Period",
    sendVote19: "You can customize the validity period of the vote",
    sendVote20: "Maximum Days to Initiate Vote",
    sendVote21: "Minimum 1 point",
    sendVote22: "Your points are less than $$$,please recharge",
    sendVote23: "提交中...",
  },
  headerNavbar: {
    headerNavbar1: "Invite Friends",
    headerNavbar2: "Earn up to 10% commission",
    headerNavbar3: "You can earn commissions when your referred friends purchase or upgrade membership.",
    headerNavbar4: "Rules ",
    headerNavbar5: "Your Total Commission",
    headerNavbar6: "Your Commission Rate",
    headerNavbar7: "Earned",
    headerNavbar8: "Invited",
    headerNavbar9: "Invitation Link",
    headerNavbar10: "Address",
    headerNavbar11: "Yesterday",
    headerNavbar12: "Today",
    headerNavbar13: "Accumulated Earnings",
    headerNavbar14: "Invitations",
    headerNavbar15: "Invitation Records",
    headerNavbar16:
      "When a friend purchases or upgrades a membership, the inviter can receive a 10% commission on the amount of the friend's purchase or upgrade, while the indirect inviter can receive a 5% commission, which is a corresponding value point.The effective user means the user who purchased the membership",
    headerNavbar17: "*Valid friends means the users who purchased SBT.",
    headerNavbar18: "Personal Center",
    headerNavbar19: "Invitation/Commission",
    headerNavbar20: "Inbox",
    headerNavbar21: "Hide Assets",
    headerNavbar22: "Hide and Block",
    headerNavbar23: "Complaints and Suggestions",
    headerNavbar24: "Logout",
    headerNavbar25: "Available Points",
    headerNavbar26: "邀请二维码",
    headerNavbar27: "我的探索",
    headerNavbar28: "邀请码",
    headerNavbar29: "复制",
    headerNavbar30: "邀请规则",
  },
  payModal: {
    payModal1: "Recharge must be an integer",
    payModal2: "Please enter recharge amount",
    payModal3: "Submit",
    payModal4: "Recharge amount cannot be empty",
    payModal5: "Become a Member",
    payModal6: "Becoming a member allows participation in games",
    payModal7: "Coefficient",
    payModal8: "Pay",
    payModal9: "Insufficient balance",
    payModal10: "Withdrawal Application",
    payModal11: "Please enter the number of points you want to withdraw",
    payModal12: "1 point = 1 USDT, minimum withdrawal amount is 10, in multiples of 5",
    payModal23: "提现审核周期24小时",
    payModal13: "Please enter the withdrawal amount",
    payModal14: "Recharge amount must be greater than or equal to 20",
    payModal15: "Please enter a multiple of 5",
    payModal16: "Withdrawal application submitted successfully",
    payModal17: "Casting",
    payModal18: "Block Confirming",
    payModal19: "Casting Successful",
    payModal20: "Withdrawal amount cannot be empty",
    payModal21: "Insufficient withdrawable balance",
    payModal22: "Withdrawal amount must be greater than or equal to 20",
  },
  mixin: {
    mixin1: "Payment in progress",
    mixin2: "Block confirming successful",
  },
  vuex: {
    vuex1: "Wallet connecting",
    vuex2: "MetaMask not installed",
    vuex3: "You are not logged in; please log in first",
    vuex4: "Not logged in",
  },
  request: {
    "-100": "Server not registered",
    "-101": "Configuration does not exist",
    "-102": "Client parameter error",
    "-103": "Insufficient USDT",
    "-106": "Server busy",
    "-107": "Unsupported URI",
    "-108": "Insufficient permissions",
    "-109": "Administrator already exists",
    "-110": "Administrator does not exist",
    "-111": "Not in activity time",
    "-112": "Order timeout",
    "-114": "User does not exist",
    "-115": "Game does not exist",
    "-200": "Unable to connect to authentication server",
    "-201": "Account already logged in",
    "-202": "Server at maximum capacity",
    "-203": "This type of login is not open",
    "-204": "Incorrect account format",
    "-205": "Incorrect password format",
    "-206": "Please enter the correct referrer code",
    "-208": "Account already logged in on another device",
    "-209 ": "Account does not have permission to log in, please contact the administrator",
    "-210": "Account does not exist",
    "-211": "Role does not exist",
    "-212": "Player does not exist",
    "-213": "Referrer cannot be empty",
    "-214": "Referrer cannot be oneself",
    "-215": "Referrer exists in own chain",
    "-216": "Referrer does not exist",
    "-400": "Invalid type",
    "-401": "Incorrect amount",
    "-402": "Can only apply once in 24 hours",
    "-500": "Can only withdraw once in 24 hours",
    "-600": "Insufficient VIP level",
    "-601": "Single bet exceeds the current VIP limit",
    "-602": "Accumulated bet exceeds the current VIP limit",
    "-700": "Insufficient valid team members",
    "-800": "Project does not exist",
    "-900": "Service not yet opened",
    "-1000": "VIP赠送积分不存在",
    "-1001": "VIP赠送积分已解锁",
    "-1002": "转化金额不足",
    "-10201": "暂无订单可抢",
    "-10202": "无效账户",
    "-10203": "订单超时",
    "-10204": "金额不足",
    "-20000": "服务器繁忙",
    "-20001": "客户端参数错误 ",
    "-20002": "不支持的URI",
    "-20003": "无权限",
    "-20004": "对抗服务未开启",
    "-20005": "服务不在开放时间 ",
    "-20006": "用户不存在",
    "-20100": "未登录",
    "-20200": "创建已达上限",
    "-20201": " 无法连接APP服务器",
    "-20202": "游戏未开始",
    "-20203": "阵营类型错误",
    "-20204": "下注金额错误",
    "-20205": "游戏不存在",
    10001: "Operation failed",
    20001: "Exceeding the maximum number of ongoing debates",
    20002: "Exceeding the maximum number of ongoing debates initiated by a single person",
    20003: "Frequent voting operations, exceeding the minute limit",
    20004: "Expired days are not within the valid days range",
    20013: "Debate or debate option does not exist",
    20023: "Debate has already ended",
    20033: "Debate has not yet started",
    20043: "Reason not filled in or exceeding the 200 word limit",
    20202: "辩论维护中",
    request42: "Login session has expired, please log in again",
  },
  confront: {
    confront1: "购买记录",
    confront2: "开奖名单",
    confront3: "奖励规则",
    confront4: "1份",
    confront5: "未中奖",
    confront6: "奖池",
    confront7: "买入",
    confront8: "分红",
    confront9: "中奖方",
    confront10: "我的奖励",
    confront11: "一等奖",
    confront12: "二等奖",
    confront13: "三等奖",
    confront14: "平分奖",
  },
  dk: {
    ydj: "一等奖",
    edj: "二等奖",
    sdj: "三等奖",
    pfj: "平分奖",
    fhjl: "分红奖励",
    zjdz: "中奖地址",
    kjm: "开奖码",
    jlje: "奖励金额",
    fqdk: "发起对抗",
    text1: "发起对抗需同时满足以下条件",
    text2: "仅限于钻石会员发起对抗游戏",
    text3: "一周只能发起一次对抗游戏",
    text4: "伞下必须满足100人",
    text5: "需缴纳200U",
    text6: "对抗游戏规则",
    text7: `用户选择红方或者蓝方，用积分购买（V）股，成交后获得交易哈希值。<br /><br />
    每次购买W股的积分会分为：45%进入总奖池，20%进入红方奖池，20%进入蓝方奖池，5%交易手续费，5%项目方，3%直推奖，2%间推奖。V股价2积分<br /><br />
    累计购买V股10000积分进行分红一次，依次累计分红10次或5天时间倒计时后开奖。<br /><br />
    V股累计购买10000积分，红蓝双方进行平分奖池奖金（20%的红蓝奖池，加权分红）<br /><br />
    累计分红10次后或5天时间到，进行抽奖。如果抽中红方哈希值中一等奖则进行以下奖项分配<br /><br />
    一等奖：1名 独得总奖池25%奖励 （一等奖为红方）<br /><br />
    二等奖：10名 平分总奖池20%奖励<br /><br />
    三等奖：40名 平分总奖池20%奖励<br /><br />
    所有红方平分剩余的35%奖励 （蓝方中奖同样）<br /><br />
    120小时满后也准时开奖。<br /><br />`,
    yjs: "已结束",
    dqjc: "当前奖池",
    jf: "积分",
    qyqhy: "去邀请好友",
    vg: "V股",
    hydj: "会员等级",
    dcgm: "单次限购",
    xs: "系数",
    qtxmrgs: "请填写买入股数",
    zjf: "总积分",
    xhjf: "消耗积分",
    mr: "买入",
    zw: "暂无",
  },
  gift: {
    gift1: "会员福利大放送",
    gift2: "买",
    gift3: "送",
    gift4: "白银会员",
    gift5: "黄金会员",
    gift6: "钻石会员",
    gift7: "每日",
    gift8: "%收益，",
    gift9: "小时收益到账",
    gift10: "累计收益",
    gift11: "倍",
    gift12: "备注：",
    gift13: "以上赠送积分无法提现,收益积分可以参与游戏或者提现",
    gift14: "领取积分",
    gift15: "可领福利积分",
    gift16: "手续费",
    gift17: "实际到账",
    gift18: "立即领取",
    gift19: "尊敬的用户，您还未充值该会员",
    gift20: "暂无法领取会员福利",
    gift21: "最大领取积分",
    gift22: "剩余结算积分",
    gift23: "尊敬的",
    gift24: "您暂未开启福利积分领取",
    gift25: "开启领取积分",
    gift26: "待释放积分",
    gift27: "请输入领取积分",
    gift28: "领取成功",
  },
  auction: {
    check: "我的买单",
    sell: "我的卖单",
    detail: "订单详情",
    title1: "待支付订单",
    title2: "待寄售订单",
    title3: "寄售中订单",
    title4: "已完成订单",
    auction1: "买入",
    auction2: "竞价区间",
    auction3: "敬请期待",
  },
};
