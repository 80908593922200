<template>
  <el-dialog :modal-append-to-body="false" :visible="show" :title="$t('payModal.payModal10')" :width="registerDialogWidth" @close="$emit('update:show', false)" center>
    <!-- <van-dialog :value="show" title="提现申请" show-cancel-button @confirm="txFn()" @cancel="$emit('update:show', false)"> -->
    <div>
      <div class="text-8D94BF px-3 text-center text-9">
        <!-- {{$t('payModal.payModal11')}} -->
        <div>{{ $t("payModal.payModal23") }}</div>
        <div>{{ $t("payModal.payModal24").replace("$$$", perMintFee) }}</div>
        <!-- <div>请输入您要提现的积分数量</div>
        <div>1.1积分=1USDT</div>
        <div>2.20积分起</div>
        <div>3.必须是5的倍数</div> -->
      </div>
      <van-field class="Frame59" v-model.number="price" type="digit" :placeholder="$t('payModal.payModal13')" />
    </div>
    <div class="text-center pt-5">
      <button class="btn text-white rounded-pill w-75" :class="loading ? 'bg-d8d8d8' : 'bg-dark'" :disabled="loading" @click="txFn()">{{ $t("payModal.payModal3") }}</button>
    </div>
    <!-- </van-dialog> -->
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: { show: Boolean },
  data() {
    return {
      price: "",
      loading: false,
      perMintFee: "",
    };
  },
  methods: {
    ...mapActions("user", ["getPropertys"]),
    ...mapActions("webAbi", ["getUSDT", "getAuth", "payVip"]),
    /**
     * 提现接口
     * @param {积分} u
     */
    async txFn() {
      this.$emit("update:show", false);
      if (!this.price) return this.$toast(this.$t("payModal.payModal4"));
      if (this.price * 1 > this.propertys[3]) return this.$toast(this.$t("payModal.payModal9"));
      // if (this.price < 20) return this.$toast(this.$t('payModal.payModal14'));
      // if (this.price % 5 != 0) return this.$toast(this.$t('payModal.payModal15'));
      if (this.loading) return;
      this.loading = true;
      try {
        const { data: res } = await this.$http.post("/app/applyMint", { type: 3, amount: this.price + "" });
        await this.getPropertys();
        this.$toast(this.$t("payModal.payModal16"));
      } catch (error) {}
      this.loading = false;
    },
  },
  async mounted() {},
  computed: {
    ...mapState("user", ["propertys", "authorization"]), // propertys[3] 钱包余额
    ...mapState("app", ["registerDialogWidth"]),
  },
  watch: {
    async show() {
      if (this.show) {
        await this.getPropertys(); // 刷新用户属性
        console.log(this.authorization, "=========");
        if (this.authorization) {
          try {
            const { data: res } = await this.$http.post("/app/getPerMintFee");
            this.perMintFee = res.perMintFee * 1;
          } catch (error) {}
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
