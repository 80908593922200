import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css";
import Cookies from "js-cookie";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang"; // internationalization
import Vant from "vant";
import "vant/lib/index.css";
import { Lazyload, ImagePreview, Dialog  } from "vant";
import VConsole from "vconsole";
process.env.NODE_ENV === "development" && new VConsole();

import service from "@/utils/request";
import service2 from "@/utils/request2";
import service3 from "@/utils/request3";
import service5 from "@/utils/request5";
Vue.prototype.$http = service; // 老汪的请求
Vue.prototype.$http2 = service2; // 李老表的请求
Vue.prototype.$http3 = service3; // 老汪的请求 对抗
Vue.prototype.$http5 = service5; // 老汪的请求 竞拍

import api from "@/api/http.js";
Vue.prototype.$api = api;

Vue.use(Lazyload, ImagePreview, Dialog );
Vue.use(Vant, {
  i18n: (key, value) => i18n.t(key, value),
});
import { Toast } from "vant";
Vue.prototype.$toast = Toast;

// 全局混入
import mixin from "./mixin";
Vue.mixin(mixin);
import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs;
// 时间  转  时间毫秒时间戳
Vue.prototype.$toUnix = function (time) {
  if (!time) return "";
  return dayjs(time).valueOf();
};
Vue.prototype.$setTime = function (time) {
  if (!time) {
    return "";
  }
  return this.$dayjs(time).format("YYYY/MM/DD HH:mm:ss");
  if (this.$store.getters.language === "zh-cn") {
    return this.$dayjs(time).format("YYYY/MM/DD HH:mm:ss");
  } else {
    return this.$dayjs(time).format("DD/MM/YYYY HH:mm:ss");
  }
};

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  size: Cookies.get("size") || "medium", // set element-ui default size
  i18n: (key, value) => i18n.t(key, value),
});

// 过滤器
import * as filters from "./filters"; // global filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  beforeCreate() {
    // this.$bus.$emit("payVip") 触发事件   this.$bus.$on("payVip",function) 监听事件
    Vue.prototype.$bus = this; // 安装全局事件总线，$bus就是当前应用的vm
  },
}).$mount("#app");
