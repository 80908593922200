import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/index.vue";
import store from "@/store";
import { Toast } from "vant";
import i18n from "@/lang";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Plouto",
    redirect: "/home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user.vue"),
  },
  {
    path: "/game",
    name: "game",
    component: () => import("../views/game.vue"),
  },
  {
    path: "/ranking",
    name: "ranking",
    component: () => import("../views/ranking.vue"),
  },
  {
    path: "/vip",
    name: "vip",
    component: () => import("../views/vip.vue"),
  },
  {
    // 发起辩论
    path: "/vip/sendVote",
    name: "sendVote",
    component: () => import("../views/sendVote/index.vue"),
  },
  {
    // 发起对抗
    path: "/vip/sendRedBlue",
    name: "sendRedBlue",
    component: () => import("../views/sendRedBlue/index.vue"),
  },
  {
    // 公告
    path: "/notice/index",
    name: "index",
    component: () => import("../views/notice/index.vue"),
  },
  {
    // 公告详情
    path: "/notice/xq",
    name: "xq",
    component: () => import("../views/notice/xq.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.DebateShow = false; // 辩论轮询
  //全局路由守卫：需要登录
  document.title = "Plouto";
  let whitePath = ["/login"];
  // Toast
  if (whitePath.includes(to.path)) return next();
  // 有token直接放行
  if (store.state.user.token) return next();
  Toast(i18n.t("vuex.vuex3"));
  if (from.path != "/login") next("/login");
});

export default router;
