<template>
  <el-dialog :visible="show" :title="$t('wallet.topUp')" :width="registerDialogWidth" @close="$emit('update:show', false)" center :modal-append-to-body="false">
    <!-- <van-dialog :value="show" title="充值" show-cancel-button @confirm="payu(price, 1)" @cancel="$emit('update:show', false)"> -->
    <div class="text-8D94BF px-3 text-center text-9">{{$t('payModal.payModal1')}}</div>
    <div>
      <van-field class="Frame59" v-model="price" type="digit" :placeholder="$t('payModal.payModal2')" />
    </div>
    <div class="text-center pt-5">
      <button class="btn text-white rounded-pill w-75" :class="loading ? 'bg-d8d8d8' : 'bg-dark'" :disabled="loading" @click="payu(price, 1)">{{$t('payModal.payModal3')}}</button>
    </div>
    <!-- </van-dialog> -->
  </el-dialog>
</template>

<script>
import pay from "@/mixin/pay";
import { mapState } from "vuex";
export default {
  mixins: [pay],
  data() {
    return {
      price: "",
      loading: false,
    };
  },
  methods: {
    /**
     * 充值接口
     * @param {充值的金额} u
     */
    async payu(price, type = 1) {
      this.$emit("update:show", false);
      if (!this.price) return this.$toast(this.$t('payModal.payModal4'));
      this.cz(price, type);
    },
  },
  async mounted() {},
  computed: { ...mapState("app", ["registerDialogWidth"]) },
  watch: {},
};
</script>

<style lang="scss">
</style>
