import { mapState, mapActions } from "vuex";
import { crono } from "vue-crono";
export default {
  name: "",
  // 轮询插件
  cron: [{ time: 2000, method: "queryRechargeResult", autoStart: false }], // 2秒
  mixins: [crono],
  props: { show: Boolean },
  data() {
    return { loading: false, type: "" };
  },
  methods: {
    ...mapActions("user", ["getPropertys"]),
    ...mapActions("webAbi", ["getUSDT", "getAuth", "payVip"]),
    /**
     * 充值接口
     * @param {充值的金额} u
     */
    async cz(price, type = 2) {
      if (this.loading) return;
      try {
        this.loading = true;
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t("mixin.mixin1"),
        });

        // 获取用户钱包usdt余额
        let usdt = await this.getUSDT({
          type: process.env.NODE_ENV === "development" ? "wei" : "mwei",
          // type: "mwei",
          address: this.address,
        });
        console.log("usdt", usdt, this.address);
        if (usdt * 1 < price) {
          this.$toast(this.$t("wallet.rechargeText"));
          await this.sleep(2000);
          throw "";
        }
        // 检查是否
        await this.getAuth(this.rechargeAddress);
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t("payModal.payModal18"),
        });

        // 支付前请求
        await this.$http.post("/app/readyRecharge");
        const amount = this.web3.utils.toWei(price + "", process.env.NODE_ENV === "development" ? "wei" : "mwei") * 1;
        // const amount = price * 1;
        await this.payVip({ address: this.address, type, amount });
        // this.$toast.loading({
        //   duration: 0, // 持续展示 toast
        //   forbidClick: true,
        //   message: this.$t('mixin.mixin1'),
        // });
        // console.log("充值成功");
        this.type = type;
        this.$cron.start("queryRechargeResult");
        await this.sleep(5000);
        this.loading = false;
      } catch (error) {
        console.log("error", error);
        this.$toast.clear();
        this.loading = false;
        if (error.message) {
          this.$toast(error.message);
        }
      }
      // this.$toast.clear();
    },
    // 轮询查询订单状态
    async queryRechargeResult() {
      try {
        await this.$http.post("/app/queryRechargeResult");
        this.$cron.stop("queryRechargeResult");
        await this.getPropertys(); // 刷新用户属性
        this.loading = false;
        this.$toast.clear();
        this.$toast(this.$t("mixin.mixin2"));
        this.$emit("update:show", false);
        if (this.type == 2) {
          this.$bus.$emit("VIP");
        } else {
          this.$bus.$emit("PAY");
        }
      } catch (error) {}
    },
  },
  async mounted() {},
  computed: {
    ...mapState("user", ["address", "propertys"]),
    ...mapState("webAbi", ["web3", "rechargeAddress"]),
  },
  watch: {
    async show() {
      if (this.show) {
        await this.getPropertys(); // 刷新用户属性
        this.vip = this.propertys[100];
        this.usdt = await this.getUSDT({
          type: process.env.NODE_ENV === "development" ? "wei" : "mwei",
          // type: "mwei",
          address: this.address,
        });
      }
    },
  },
};
